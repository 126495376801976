import styled from 'styled-components';

export const Desktop = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    border-top: 1px solid #f3f3f7;

    &:nth-last-of-type(1) {
        border-bottom: 1px solid #f3f3f7;
    }
`;

export const Mobile = styled.div`
    border-bottom: 1px solid #f3f3f7;
    padding: 15px 15px 30px;
    // margin-bottom: 40px;

    &:nth-last-of-type(1) {
        border-bottom: none;
    }
`;

export const Top = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f3f3f7;
    padding: 15px 0;
`;

export const Bottom = styled.div`
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Block = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    border-top: 1px solid #f3f3f7;

    &:nth-last-of-type(1) {
        border-bottom: 1px solid #f3f3f7;
    }

    @media (max-width: 979px) {
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

export const Image = styled.img`
    width: 60px;
    height: auto;
    margin-right: 10px;

    @media (max-width: 979px) {
        width: 40px;
    }
`;

export const NameAndDescription = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media (max-width: 979px) {
        flex-basis: 80%;
    }
`;

export const Name = styled.div`
    font-size: 18px;
    margin-bottom: 5px;
`;

export const Description = styled.div`
    font-size: 12px;
    color: rgba(0, 0, 0, .6);
`;

export const Counts = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96px;
    height: 32px;
    background-color: #f3f3f7;
    border-radius: 10px;
    margin-right: 100px;

    @media (max-width: 979px) {
        margin-right: 0;
    }
`;

export const CountButton = styled.button`
    font-family: Stapel, sans-serif;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 30px;
    padding: 0 8px;

    color: ${props => props.disabled ? '#8e8e8e' : '#000'}
`;

export const Price = styled.div`
    font-size: 18px;
    margin-right: 100px;

    @media (max-width: 979px) {
        margin-right: 0;
    }
`;

export const Remove = styled.button`
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

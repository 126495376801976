import { configureStore } from '@reduxjs/toolkit';

import appSlice from './appSlice';
import cartSlice from './cartSlice';
import pizzaSlice from './pizzaSlice';
import drinksSlice from './drinksSlice';
import bannerSlice from './bannerSlice';
import othSlice from './othSlice';
import zakSlice from './zakSlice';
import salSlice from './salSlice';

export default configureStore({
    reducer: {
        app: appSlice,
        cart: cartSlice,
        pizza: pizzaSlice,
        drinks: drinksSlice,
        banner: bannerSlice,
        oth: othSlice,
        zak: zakSlice,
        sal: salSlice,
    },
});

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik'

import { addOrder } from '../../../api';

import ModalComponent from '../../modal';
import Loading from '../../dotsLoading';
import {
    Block,
    Title,
    PhoneNumberBlock,
    PhoneNumberLabel,
    PhoneBlock,
    PhoneCode,
    PhoneInput,
    ConfirmBlock,
    ConfirmButton,
    LoadingBlock,
} from './components';
import { PhoneContainer, PhoneError } from './components';

const OrderConfirmModal = ({ onClose, onConfirm }) => {
    const { items } = useSelector(state => state.cart);

    const [loading, setLoading] = useState(false);

    const handleFormSubmit = async (values) => {
        setLoading(true)

        window.gtag('event', 'conversion', {
            'send_to': 'AW-993808931/B_L_CJO0_7sDEKOk8dkD',
            'transaction_id': ''
        });

        
        const products = items.map((item) => { 
            return {
                product_id: item.product.id,
                product_count: item.count,
            } 
        });

        products.push({
            product_id: 9,
            product_count: 1,
        });
        
        const params = {
            phone_number: values.phone,
            products,
        };

        await addOrder(JSON.stringify(params));

        onConfirm();
    };

    const { values, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            phone: '',
        },
        validationSchema: yup.object().shape({
            phone: yup.string().required('Пожалуйста, введите номер телефона').length(9, 'Номер телефона должен состоять из 9 цифр')
        }),
        onSubmit: handleFormSubmit,
    });

    const handlePhoneInput = (value) => {
        const number = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        setFieldValue('phone', number);
    };

    return (
        <ModalComponent size="small" onClose={onClose}>
            <Block>
                <Title>Подтверждение заказа</Title>

                <PhoneNumberBlock>
                    <PhoneNumberLabel>Номер телефона</PhoneNumberLabel>
                    
                    <PhoneContainer>
                        <PhoneBlock error={errors.phone}>
                            <PhoneCode>+992</PhoneCode>

                            <PhoneInput 
                                type="tel"
                                value={values.phone}
                                onChange={(event) => handlePhoneInput(event.target.value)}
                                maxLength="9"
                                autoFocus
                            />
                        </PhoneBlock>

                        <PhoneError>{errors.phone}</PhoneError>
                    </PhoneContainer>
                </PhoneNumberBlock>

                <ConfirmBlock>
                    <ConfirmButton onClick={() => handleSubmit()}>
                        Отправить
                    </ConfirmButton>
                </ConfirmBlock>
            </Block>
            
            {loading && (
                <LoadingBlock>
                    <Loading />
                </LoadingBlock>
            )}
        </ModalComponent>
    )
};

export default OrderConfirmModal;

import styled from 'styled-components';

export const Component = styled.div`
    display: none;
    margin-top: 15px;

    @media (max-width: 599px) {
        display: block;
    }
`;

export const Block = styled.div`
    overflow: auto;
    white-space: nowrap;
    height: 240px;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 450px) {
        height: 170px;
    }
`;

export const Item = styled.div`
    display: inline-block;
    width: 88%;
    height: 100%;
    margin-right: 15px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;

    @media (max-width: 450px) {
        width: 85%;
        margin-right: 10px;
    }

    &:nth-last-of-type(1) {
        margin-right: 0;
    }
`;

export const ItemImage = styled.img`
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const LoadingBlock = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .9);

    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

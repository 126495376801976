import React from 'react';

import { callCenterPhone, location } from '../../../../configs';

import { ColumnTitle, ColumnItem, LinkPhone } from '../../components';

const ContactsBlock = () => {
    return (
        <>
            <ColumnTitle>Контакты</ColumnTitle>
            
            <ColumnItem>
                Тел: <LinkPhone href={`tel:${callCenterPhone}`}>{callCenterPhone}</LinkPhone>
            </ColumnItem>

            <ColumnItem>
                Адрес: {location}
            </ColumnItem>
        </>
    )
};

export default ContactsBlock;

import { createSlice } from '@reduxjs/toolkit';

export const zakSlice = createSlice({
    name: 'zak',
    initialState: {
        items: [],
        isLoading: true,
    },
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
});

export const { setItems, setIsLoading } = zakSlice.actions;

export default zakSlice.reducer;

import React, { useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';

import { setItems, setMobileItems, setIsLoading } from '../../../../store/bannerSlice';
import { getBanner, getBannerMobile } from '../../../../api';
import { bannerMediaUrl } from '../../../../configs';

import { Container } from '../../../../components/ui';
import { 
    Block,
    Slide,
    Image,
    LoadingBlock,
} from './components';
import Loading from '../../../../components/dotsLoading';

const BannerComponent = () => {
    const dispatch = useDispatch();
    const { items, isLoading } = useSelector(state => state.banner);

    useEffect(() => {
        getBannerFromServer();
    }, []);

    const getBannerFromServer = async () => {
        const result = await getBanner();   
        dispatch(setItems(result));

        const resultMobile = await getBannerMobile();
        dispatch(setMobileItems(resultMobile));
        
        dispatch(setIsLoading(false));
    };

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        pauseOnHover: true,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
        draggable: true,
        useCSS: true,
        cssEase: 'linear',
        dotsClass: 'slick-dots banner-dots',
        responsive: [
            {
                breakpoint: 599,
                settings: {
                    dots: false,
                }
            }
        ]
    };

    const render = useMemo(() => {
        return (
            <Slider {...settings}>
                {items.map((item) => {
                    return (
                        <Slide key={`banner-${item.slider_id}`}>
                            <Image src={`${bannerMediaUrl}${item.slider_pic}`} alt="banner-image" />
                        </Slide>
                    )
                })}
            </Slider>
        );
    }, [items]);

    return (
        <Container>
            <Block>
                {isLoading ? (<LoadingBlock><Loading /></LoadingBlock>) : render}
            </Block>
        </Container>
    )
};

export default BannerComponent;

import styled from 'styled-components';

export const Component = styled.div`
    position: sticky;
    top: 75px;
    background-color: #fff;
    z-index: 50;
    padding: 0 0 15px;

    @media (max-width: 979px) {
        top: 56px;
    }

    @media (max-width: 599px) {
        display: none;
    }
`;

export const Block = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const NavigationBlock = styled.div`
    display: flex;
`;

export const NavigationItem = styled.button`
    margin-right: 18px;
    font-size: 14px;
`;

export const CartButton = styled.button`
    font-family: Stapel, sans-serif;
    padding: 12px 30px;
    font-size: 14px;
    border-radius: 10px;
    border: none;
    background-color: #FF6900;
    color: #fff;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    @media (max-width: 599px) {
        display: none;
    }
`;

export const CartButtonBadge = styled.div`
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #FFF0E5;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    color: #FF6900;
    display: flex;
    align-items: center;
    justify-content: center;
`;
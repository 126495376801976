import { apiUrl, apiUrl2 } from '../configs';

const getRequest = async url => {
    let response = await fetch(url);
    return await response.json();
};

const postRequest = async (url, data) => {
    let response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: data
    });
    return await response.json();
};

export const getProducts2 = async (categoryId) => {
    return await getRequest(`${apiUrl2}getOtherList2/${categoryId}`);
};

export const getProducts = async (categoryId) => {
    return await getRequest(`${apiUrl}getProductByCategory/${categoryId}`);
};

export const getBanner = async () => {
    return await getRequest(`${apiUrl}getSlider`);
};

export const getBannerMobile = async () => {
    return await getRequest(`${apiUrl}getSliderMobile`);
};

export const addRequestOrder = async (phone) => {
    return await getRequest(`${apiUrl}requestOrder?phone_number=${phone}`);
};

export const addOrder = async (data) => {
    return await postRequest(`${apiUrl}createOrder`, data);
};

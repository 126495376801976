import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 15px;
    flex-basis: 25%;

    @media (max-width: 1299px) {
        flex-basis: 33.33333%;
    }

    @media (max-width: 979px) {
        flex-basis: 50%;
        padding: 25px 15px;
    }

    @media (max-width: 599px) {
        flex-basis: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const ImageBlock = styled.div`
    @media (max-width: 599px) {
        flex-basis: 40%;
    }
`;

export const Image = styled.img`
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 10px;

    @media (max-width: 599px) {
        margin-bottom: 0;
    }
`;

export const InfoBlock = styled.div`
    @media (max-width: 599px) {
        flex-basis: 55%;
    }
`;

export const Title = styled.div`
    font-size: 24px;
    margin-bottom: 10px;

    @media (max-width: 959px) {
        font-size: 20px;
    }
`;

export const Description = styled.div`
    font-size: 14px;

    @media (max-width: 959px) {
        font-size: 12px;
    }
`;

export const PriceAndAdd = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

export const Price = styled.div`
    font-size: 24px;

    @media (max-width: 599px) {
        display: none;
    }
`;

export const Add = styled.button`
    font-family: Stapel, sans-serif;
    padding: 10px 25px;
    font-size: 14px;
    border-radius: 10px;
    border: none;
    background-color: #FFF0E5;
    color: #C75200;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    @media (max-width: 599px) {
        display: none;
    }
`;

export const AddWithPrice = styled.button`
    display: none;
    font-family: Stapel, sans-serif;
    padding: 10px 25px;
    font-size: 14px;
    border-radius: 10px;
    border: none;
    background-color: #FFF0E5;
    color: #C75200;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    @media (max-width: 599px) {
        display: inline-block;
    }
`;

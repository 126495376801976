import styled from 'styled-components';

export const Container = styled.div`
    width: 1280px;
    padding: 0 15px;
    margin: 0 auto;

    @media (max-width: 1299px) {
        width: 960px;
    }

    @media (max-width: 979px) {
        width: 580px;
    }

    @media (max-width: 599px) {
        width: 100%;
    }
`;
import React from 'react';

import { Container } from '../../../../components/ui/index';
import { 
    Block, 
    Title,
    Item,
    ItemNumberBlock,
    ItemNumber,
    ItemTextBlock,
    ItemTextTitle,
    ItemTextDescription, 
} from './components';

const WhyUsComponent = ({ whyUsRef }) => {

    return (
        <Container ref={whyUsRef}>
            <Block>
                <Title>Почему мы?</Title>

                <Item>
                    <ItemNumberBlock>
                        <ItemNumber>
                            1
                        </ItemNumber>
                    </ItemNumberBlock>

                    <ItemTextBlock>
                        <ItemTextTitle>
                            Удобно
                        </ItemTextTitle>

                        <ItemTextDescription>
                            Позвоните нам или оставьте свой номер телефона. Наш оператор перезвонить вам в течение 3х минут. Поможет вам подобрать пиццу и оформить ваш заказ. А курьер привезет вам максимум за 59 минут
                        </ItemTextDescription>
                    </ItemTextBlock>
                </Item>

                <Item>
                    <ItemNumberBlock>
                        <ItemNumber>
                            2
                        </ItemNumber>
                    </ItemNumberBlock>

                    <ItemTextBlock>
                        <ItemTextTitle>
                            Вкусно
                        </ItemTextTitle>

                        <ItemTextDescription>
                            Придерживаясь всех технологий и рецептов, наши пиццевокеры работают на качество и результат, от чего пиццы получаются по-настоящему вкусными.
                        </ItemTextDescription>
                    </ItemTextBlock>
                </Item>

                <Item>
                    <ItemNumberBlock>
                        <ItemNumber>
                            3
                        </ItemNumber>
                    </ItemNumberBlock>

                    <ItemTextBlock>
                        <ItemTextTitle>
                            Не дорого
                        </ItemTextTitle>

                        <ItemTextDescription>
                            У нас работает дружелюбная и очень маленькая компания специалистов и потому, мы не используем большие проценты на наших продуктах.
                        </ItemTextDescription>
                    </ItemTextBlock>
                </Item>
            </Block>
        </Container>
    )
};

export default WhyUsComponent;
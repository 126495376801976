import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Container } from '../../../../components/ui';
import {
    Component,
    Block,
    NavigationBlock,
    NavigationItem,
    CartButton,
    CartButtonBadge,
} from './components';

const NavigationComponent = ({
    pizzasRef,
    drinksRef,
    howToOrderRef,
    whyUsRef,
}) => {
    const { items } = useSelector(state => state.cart);
    const history = useHistory();

    const scrollToPosition = useCallback((top) => {
        window.scrollTo({
            top,
            behavior: 'smooth',
        });
    }, []);
    
    return (
        <Component>
            <Container>
                <Block>
                    <NavigationBlock>
                        <NavigationItem onClick={() => scrollToPosition(pizzasRef.current.offsetTop - 116)}>
                            Пицца
                        </NavigationItem>

                        <NavigationItem onClick={() => scrollToPosition(drinksRef.current.offsetTop - 116)}>
                            Напитки
                        </NavigationItem>

                        <NavigationItem 
                            onClick={() => scrollToPosition(howToOrderRef.current.offsetTop - 116)}
                            hideOnMobile={true}
                        >
                            Как заказать?
                        </NavigationItem>

                        <NavigationItem 
                            onClick={() => scrollToPosition(whyUsRef.current.offsetTop - 116)}
                            hideOnMobile={true}
                        >
                            Почему мы?
                        </NavigationItem>
                    </NavigationBlock>

                    <CartButton onClick={() => history.push('/cart')}>
                        Корзина

                        {items.length > 0 && (
                            <CartButtonBadge>{items.length}</CartButtonBadge>
                        )}
                    </CartButton>
                </Block>
            </Container>
        </Component>
    )
};

export default NavigationComponent;

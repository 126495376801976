import React from 'react';
import styled from 'styled-components';

const LoadingBlock = styled.div`
    display: inline-block;
    position: relative;
    width: 50px;
    height: 10px;

    & div {
        position: absolute;
        top: 0px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${ props => props.color };
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    & div:nth-child(1) {
        left: 0px;
        animation: lds-ellipsis1 0.6s infinite;
    }

    & div:nth-child(2) {
        left: 0px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    & div:nth-child(3) {
        left: 20px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    & div:nth-child(4) {
        left: 40px;
        animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(20px, 0);
        }
    }
`;

const Loading = ({ color }) => {
    return (
        <LoadingBlock color={ color || '#000' }>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </LoadingBlock>
    )
};

export default Loading;
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'react-simple-snackbar';

import { mediaUrl } from '../../../configs';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { setItem } from '../../../store/cartSlice';

import ModalComponent from '../../modal';
import {
    Block,
    ImageBlock,
    Image,
    InfoBlock,
    Name,
    SizeAndWeight,
    Description,
    Price,
    TypeSelectorBlock,
    TypeSelectorItem,
    AddToCartButtonBlock,
    AddToCartButton,
} from './components';

const ProductModal = ({ product, onClose }) => {
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const [openSnackbar] = useSnackbar({
        position: width > 599 ? 'bottom-right' : 'top-center',
        style: {
            zIndex: 200,
            backgroundColor: '#04aa6d',
        }
    });

    const [isActiveType, setIsActiveType] = useState(null);
    const [activeTypeIndex, setActiveTypeIndex] = useState(0);

    const { name, products } = product;

    useEffect(() => {
        setIsActiveType(products.products[activeTypeIndex].id);
    }, [activeTypeIndex, products]);

    const handleAddToCart = () => {
        openSnackbar('Добавлено в корзину!', 3000);
        dispatch(setItem({
            type: 'pizza',
            name,
            product: products.products[activeTypeIndex],
        }))
    };

    return (
        <ModalComponent onClose={onClose}>
            <Block>
                <ImageBlock>
                    <Image src={`${mediaUrl}${products.products[activeTypeIndex].product_pic}`} alt="product-image" />
                </ImageBlock>
                
                <InfoBlock>
                    <Name>{name}</Name>

                    <SizeAndWeight>{products.products[activeTypeIndex].product_short}</SizeAndWeight>

                    <Description>{products.products[activeTypeIndex].product_about}</Description>

                    <Price>{products.products[activeTypeIndex].product_price} tjs</Price>

                    <TypeSelectorBlock>
                        {products.products.map((item, index) => {
                            return (
                                <TypeSelectorItem 
                                    key={`pizza-product-${item.id}`}
                                    isActive={isActiveType === item.id} 
                                    onClick={() => {setIsActiveType(item.id); setActiveTypeIndex(index)}}
                                >
                                    {item.product_name}
                                </TypeSelectorItem>
                            )
                        })}
                    </TypeSelectorBlock>

                    <AddToCartButtonBlock>
                        <AddToCartButton onClick={() => handleAddToCart()}>
                            Добавить в корзину за {products.products[activeTypeIndex].product_price} tjs
                        </AddToCartButton>
                    </AddToCartButtonBlock>
                </InfoBlock>
            </Block>
        </ModalComponent>
    )
};

export default ProductModal;

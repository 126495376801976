import styled from 'styled-components';

export const Component = styled.div`
    background: radial-gradient(90.97% 891.49% at 0% 95.76%, #FE9E02 0%, rgba(254, 158, 2, 0) 100%), #FF6900;
    position: relative;
`;

export const Block = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    color: #fff;
`;

export const Title = styled.div`
    font-size: 36px;
    margin-bottom: 30px;

    @media (max-width: 979px) {
        font-size: 24px;
    }
`;

export const Text = styled.div`
    font-size: 18px;
    width: 500px;
    text-align: center;

    @media (max-width: 599px) {
        width: 100%;
    }
`;

export const Form = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 30px 0;
    width: 500px;

    @media (max-width: 599px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const PhoneContainer = styled.div`
    flex-grow: 1;
    margin-right: 20px;

    @media (max-width: 599px) {
        width: 100%;
        margin: 0 0 15px 0;
    }
`;

export const PhoneBlock = styled.div`
    display: flex;
    border: 2px solid ${props => props.error ? '#ff0000' : '#fff'};
    border-radius: 10px;
    padding: 10px 15px;
`;

export const PhoneCode = styled.div`
    font-size: 18px;
    margin-right: 15px;
`;

export const PhoneInput = styled.input`
    font-family: Stapel, sans-serif;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 18px;

    &:focus {
        outline: none;
    }
`;

export const PhoneError = styled.div`
    font-size: 14px;
    color: #fff;
    margin-top: 3px;
`;

export const SendButton = styled.button`
    font-family: Stapel, sans-serif;
    padding: 16px 24px;
    font-size: 14px;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    color: #FF6900;
    line-height: 1;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    @media (max-width: 599px) {
        width: 100%;
    }
`;

export const LoadingBlock = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .9);

    display: flex;
    align-items: center;
    justify-content: center;
`;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { clearItems } from '../../store/cartSlice';

import { Container } from '../../components/ui';
import {
    Block,
    Title,
    EmptyComponent,
    Products,
    ForDeliveryPrice,
    CartPrice,
    Buttons,
    BackToMainButton,
    CreateOrderButton,
} from './components';
import OrderConfirmModal from '../../components/modals/orderConfirmModal';
import WaitAndSubscribeModal from '../../components/modals/waitAndSubscribeModal';

import { ReactComponent as ChevronLeftBlackIcon } from '../../assets/icons/chevron-left-black.svg';
import { ReactComponent as ChevronRightWhiteIcon } from '../../assets/icons/chevron-right-white.svg';
import ItemComponent from './parts/item';

const CartPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { items, sum } = useSelector(state => state.cart);
    const [showOrderConfirm, setShowOrderConfirm] = useState(false);
    const [showSubscribeModal, setShowSubscribe] = useState(false);

    const handleOrderConfirmOnConfirm = () => {
        setShowOrderConfirm(false);
        setShowSubscribe(true);
    };

    const handleSubscribeModalOnClose = () => {
        history.push('/');
        setShowSubscribe(false);
        dispatch(clearItems());
    };

    return (
        <Container>
            <Block>
                <Title>Корзина</Title>

                {items.length === 0 && (
                    <EmptyComponent>
                        Ваша корзина пуста!
                    </EmptyComponent>
                )}

                {items.length > 0 && (
                    <>
                        <Products>
                            {items.map((item, index) => {
                                return (
                                    <ItemComponent 
                                        key={`cart-product-item-${index}`}
                                        product={item}
                                        index={index}
                                    />
                                )
                            })}
                        </Products>

                        <ForDeliveryPrice>
                            За доставку - <span>10 сомони</span>
                        </ForDeliveryPrice>

                        <CartPrice>
                            Сумма заказа: <span>{sum} tjs</span>
                        </CartPrice>

                        <Buttons>
                            <BackToMainButton onClick={() => history.push('/')}>
                                <ChevronLeftBlackIcon />
                                Вернутся в меню
                            </BackToMainButton>

                            <CreateOrderButton 
                                onClick={() => setShowOrderConfirm(true)}
                                disabled={items.length === 0}
                            >
                                Оформить заказ
                                <ChevronRightWhiteIcon />
                            </CreateOrderButton>
                        </Buttons>
                    </>
                )}
            </Block>
            
            {showOrderConfirm && (
                <OrderConfirmModal 
                    onClose={() => setShowOrderConfirm(false)}
                    onConfirm={() => handleOrderConfirmOnConfirm()}
                />
            )}

            {showSubscribeModal && (
                <WaitAndSubscribeModal 
                    onClose={() => handleSubscribeModalOnClose()}
                />
            )}
        </Container>
    )
};

export default CartPage;

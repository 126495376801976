import styled from 'styled-components';

export const Component = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0 0;
    background-color: #fff;
    z-index: 50;

    &.active {
        box-shadow: 0px 0px 10px rgba(6, 5, 50, 0.1);
    }

    @media (max-width: 979px) {
        padding: 10px 0 6px;
    }
`;

export const Block = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LogoBlock = styled.div`
    display: block;

    @media (max-width: 979px) {
        display: none;
    }
`;

export const LogoBlockSmall = styled.div`
    display: none;
    align-items: center;

    @media (max-width: 979px) {
        display: flex;

        & > svg {
            display: block;
        }
    }
`;

export const DeliveryBlock = styled.div`
    font-size: 18px;
    width: 230px;

    & > span {
        color: #ff6900;
    }

    @media (max-width: 979px) {
        display: none;
    }
`;

export const PhoneAndMenuButtonBlock = styled.div`
    display: flex;
`;

export const PhoneBlock = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 979px) {
        flex-direction: column-reverse;
    }

    @media (max-width: 599px) {
        display: none;
    }
`;

export const PhoneBlockTop = styled.a`
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 2px;
    color: #000;
    text-decoration: none;

    & > svg {
        margin-right: 8px;
    }

    @media (max-width: 979px) {
        font-size: 14px;
        color: #ff6900;
        margin-top: 2px;
        margin-bottom: 0;

        & > svg {
            display: none;
        }
    }
`;

export const PhoneBlockBottom = styled.div`
    color: #999;
    font-size: 14px;
    padding-left: 30px;

    @media (max-width: 979px) {
        font-size: 10px;
        padding-left: 0;
        text-align: right;
    }
`;

export const FreeCall = styled.span`
    @media (max-width: 979px) {
        display: none;
    }
`;

export const FreeDelivery = styled.span`
    display: none;

    @media (max-width: 979px) {
        display: inline-block;
    }
`;

export const MenuButtonBlock = styled.div`
    display: none;
    align-items: center;
    justify-content: center;
    margin-left: 15px;

    @media (max-width: 979px) {
        display: flex;
    }
`;
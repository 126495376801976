import styled from 'styled-components';

export const Component = styled.div`
    display: none;

    @media (max-width: 599px) {
        display: ${props => props.isActive ? 'flex' : 'none'};
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        z-index: 200;
        background-color: #000;
    }
`;

export const Header = styled.div`
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #fff;
`;

export const HeaderLogo = styled.div``;

export const HeaderCloseButton = styled.div`
    color: #fff;
`;

export const Body = styled.div`
    flex-grow: 1;
`;

export const List = styled.div`
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
`;

export const Item = styled.button`
    font-size: 24px;
    color: #fff;
    margin-bottom: 20px;
`;

export const Contacts = styled.div`
    padding: 20px 20px 50px;
`;

export const ContactRow = styled.div`
    display: flex;
    margin-bottom: 30px;

    &:nth-last-of-type(1) {
        margin-bottom: 0;
    }
`;

export const ContactIconBlock = styled.div`
    color: #fff;
`;

export const ContactInfoBlock = styled.div`
    margin-left: 15px;
`;

export const ContactInfoTitle = styled.a`
    text-decoration: none;
    color: #fff;
    font-size: 24px;
    margin-bottom: 4px;
`;
    
export const ContactInfoDescription = styled.div`
    color: #fff;
    font-size: 16px;
`;
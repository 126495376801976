import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 0;

    @media (max-width: 959px) {
        padding: 25px 0;
    }
`;

export const Title = styled.div`
    font-size: 36px;
    margin-bottom: 30px;
    font-weight: bold;

    @media (max-width: 959px) {
        font-size: 24px;
    }
`;

export const Products = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
`;

export const LoadingBlock = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .9);

    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

import React from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import {
    Component,
    Block,
    CloseButton,
} from './components';

const ModalComponent = ({ children, size, onClose }) => {

    
    return (
        <Component>
            <Block size={size}>
                <CloseButton onClick={() => onClose()}>
                    <CloseIcon />
                </CloseButton>
                
                {children}
            </Block>
        </Component>
    )
};

export default ModalComponent;

import React from 'react';

import NavigationComponent from './parts/navigation';
import BannerComponent from './parts/banner';
import MobileBannerComponent from './parts/mobileBanner';
import DrinksComponent from './parts/drinks';
import SalComponent from './parts/sal';
import HowToOrderComponent from './parts/howToOrder';
import PizzasComponent from './parts/pizzas';
import WhyUsComponent from './parts/whyUs';
import CartButton from '../../components/cartButton';
import ZakComponent from './parts/zak';

const HomePage = ({
    pizzasRef,
    drinksRef,
    howToOrderRef,
    whyUsRef,
}) => {


    return (
        <>
            <NavigationComponent 
                howToOrderRef={howToOrderRef}
                pizzasRef={pizzasRef}
                drinksRef={drinksRef}
                whyUsRef={whyUsRef}
            />
            <BannerComponent />
            {/* <MobileBannerComponent /> */}
            <HowToOrderComponent howToOrderRef={howToOrderRef} />
            <PizzasComponent pizzasRef={pizzasRef} />
            <DrinksComponent drinksRef={drinksRef} />
            <ZakComponent />
            <SalComponent />
            
            <WhyUsComponent whyUsRef={whyUsRef} />
            <CartButton />
        </>
    )
};

export default HomePage;
import styled from 'styled-components';

export const Button = styled.a`
    display: none;
    position: fixed;
    right: 20px;
    bottom: 50px;
    background-color: #FFF0E5;
    outline: none;
    border: none;
    border-radius: 50%;
    height: 56px;
    width: 56px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 12px rgba(6, 5, 50, 0.12);

    @media (max-width: 599px) {
        display: flex;
    }
`;
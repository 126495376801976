import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 0 50px;

    @media (max-width: 959px) {
        padding: 25px 0;
    }
`;

export const Title = styled.div`
    font-size: 36px;
    margin-bottom: 30px;
    font-weight: bold;

    @media (max-width: 959px) {
        font-size: 24px;
    }
`;

export const EmptyComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;    
    height: 200px;
    font-size: 36px;
`;

export const Products = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ForDeliveryPrice = styled.div`
    padding: 20px 0;
    text-align: right;
    font-size: 20px;
    border-bottom: 1px solid #f3f3f7;

    & > span {
        color: #FF6900;
    }
`;

export const CartPrice = styled.div`
    margin: 50px 0;
    font-size: 24px;
    font-weight: bold;
    text-align: right;

    & > span {
        color: #ff6900;
    }

    @media (max-width: 599px) {
        font-size: 18px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    font-family: Stapel, sans-serif;
    padding: 15px;
    font-size: 14px;
    border-radius: 10px;
`;

export const BackToMainButton = styled(Button)`
    background-color: #f3f3f7;
    color: #000;

    & > svg {
        margin-right: 5px;
    }
`;

export const CreateOrderButton = styled(Button)`
    background-color: #FF6900;
    color: #fff;

    & > svg {
        margin-left: 5px;
    }
`;
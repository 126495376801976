import React, { useCallback } from 'react';
import styled from 'styled-components';

import { ColumnTitle, ColumnItem } from '../../components';

const Link = styled.span`
    color: #fff;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

const FaqBlock = ({
    howToOrderRef,
    whyUsRef,
}) => {
    const width = window.innerWidth;

    const scrollToPosition = useCallback((top) => {
        window.scrollTo({
            top,
            behavior: 'smooth',
        });
    }, []);

    return (
        <>
            <ColumnTitle>FAQ</ColumnTitle>
            
            <ColumnItem>
                <Link onClick={() => scrollToPosition(howToOrderRef.current.offsetTop - (width > 979 ? 130 : 56))}>Как заказать?</Link>    
            </ColumnItem>

            <ColumnItem>
                <Link onClick={() => scrollToPosition(whyUsRef.current.offsetTop - (width > 979 ? 130 : 56))}>Почему мы?</Link>    
            </ColumnItem>
        </>
    )
};

export default FaqBlock;

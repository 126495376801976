import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 20px;
    position: relative;
    min-height: 200px;

    @media (max-width: 979px) {
        padding: 25px 0;
        min-height: 150px;
    }

    @media (max-width: 599px) {
        display: none;
    }
`;

export const Slide = styled.div`
    overflow: hidder;
    border-radius: 20px;
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    display: block;
    border-radius: 20px;
`;

export const LoadingBlock = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .9);

    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

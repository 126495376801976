import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    height: 100%;

    @media (max-width: 599px) {
        flex-direction: column;
        padding-top: 60px;
    }
`;

export const ImageBlock = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    justify-constent: center;
    padding: 50px 50px 50px 30px;

    @media (max-width: 979px) {
        width: 50%;
        padding: 30px 30px 30px 10px;
    }

    @media (max-width: 599px) {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;
    }
`;

export const Image = styled.img`
    width: 100%;

    @media (max-width: 599px) {
        max-width: 480px;
        margin: 0 auto;
    }
`;

export const InfoBlock = styled.div`
    position: relative;
    width: 40%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;

    @media (max-width: 979px) {
        width: 50%;
    }

    @media (max-width: 599px) {
        width: 100%;
        height: 100%;
    }
`;

export const Name = styled.div`
    font-size: 24px;
    margin-bottom: 5px;

    @media (max-width: 599px) {
        font-weight: bold;
    }
`;

export const SizeAndWeight = styled.div`
    font-size: 14px;
    opacity: 0.6;
    margin-bottom: 8px;
`;

export const Description = styled.div`
    font-size: 14px;
    margin-bottom: 15px;
`;

export const Price = styled.div`
    font-size: 24px;
    flex-grow: 1;

    @media (max-width: 599px) {
        margin-bottom: 10px;
        flex-grow: unset;
    }
`;

export const TypeSelectorBlock = styled.div`
    display: flex;
    width: 100%;
    background: #F3F3F7;
    border-radius: 10px;
    padding: 1px;
`;

export const TypeSelectorItem = styled.div`
    flex-grow: 1;
    text-align: center;
    padding: 8px 0;
    font-size: 14px;
    border-radius: 10px;

    background-color: ${props => props.isActive ? '#fff' : 'transparent'};

    &:hover {
        cursor: pointer;
    }
`;

export const AddToCartButtonBlock = styled.div`
    @media (max-width: 599px) {
        display: flex;
        align-items: flex-end;
        flex-grow: 1;
    }
`;

export const AddToCartButton = styled.button`
    padding: 15px;
    font-size: 14px;
    border-radius: 10px;
    background-color: #FF6900;
    color: #fff;

    width: 100%;
    margin-top: 20px;
`;

import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LinksBlock = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

export const Link = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #4d4d4d;
    color: #fff;
`;

export const Copyright = styled.div`
    & > span {
        font-weight: bold;
    }
`;

import React, { useCallback } from 'react';
import styled from 'styled-components';

import { ColumnTitle, ColumnItem } from '../../components';

const Link = styled.span`
    color: #fff;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const MenuBlock = ({
    pizzasRef,
    drinksRef,
}) => {
    const width = window.innerWidth;

    const scrollToPosition = useCallback((top) => {
        window.scrollTo({
            top,
            behavior: 'smooth',
        });
    }, [width]);
    
    return (
        <>
            <ColumnTitle>Меню</ColumnTitle>
            
            <ColumnItem>
                <Link onClick={() => scrollToPosition(pizzasRef.current.offsetTop - (width > 979 ? 96 : 56))}>Пицца</Link>    
            </ColumnItem>

            <ColumnItem>
                <Link onClick={() => scrollToPosition(drinksRef.current.offsetTop - (width > 979 ? 96 : 56))}>Напитки</Link>    
            </ColumnItem>
        </>
    )
};

export default MenuBlock;

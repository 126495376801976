import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'react-simple-snackbar';

import { setItems, setIsLoading } from '../../../../store/salSlice';
import { setItem } from '../../../../store/cartSlice';
import { getProducts2 } from '../../../../api';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

import { Container } from '../../../../components/ui';
import {
    Block,
    Products,
    Title,
    LoadingBlock,
} from './components';
import ProductComponent from '../../../../components/product';
import Loading from '../../../../components/dotsLoading';

const SalComponent = () => {
    const dispatch = useDispatch();
    const { items, isLoading } = useSelector(state => state.sal);
    const { width } = useWindowDimensions();
    const [openSnackbar] = useSnackbar({
        position: width > 599 ? 'bottom-right' : 'top-center',
        style: {
            zIndex: 200,
            backgroundColor: '#04aa6d',
        }
    });

    useEffect(() => {
        getProductsFromServer();
    }, []);

    const getProductsFromServer = async () => {
        const result = await getProducts2(12);
        dispatch(setItems(result.product_list));
        dispatch(setIsLoading(false));
    };

    const handleAddToCart = (index) => {
        openSnackbar('Добавлено в корзину!', 3000);

        //items[index].id = items[index];
        const product = items[index];

        dispatch(setItem({
            type: 'oth',
            name: product.product_name,
            product,
        }))
    };

    const render = useMemo(() => {
        if (items.length>0) {
            return items.map((item, index) => {
                return (
                    <ProductComponent 
                        key={`other-${index}`}
                        image={item.product_pic}
                        title={item.product_name}
                        description={item.product_about}
                        price={`${item.product_price} tjs`}
                        buttonText="В корзину"
                        onSelect={() => handleAddToCart(index)}
                    />
                )
            });
        }

        return null;
    }, [items]);

    return (
        <Container>
            <Block>
                <Title>
                    Салаты
                </Title>

                <Products>
                    {isLoading && (
                        <LoadingBlock>
                            <Loading />
                        </LoadingBlock>
                    )}
                    
                    {render}
                </Products>
            </Block>
        </Container>
    )
};

export default SalComponent;

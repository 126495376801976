import styled from 'styled-components';

export const Component = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;

    background-color: rgba(0, 0, 0, .8);

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Block = styled.div`
    position: relative;

    width: ${props => props.size === 'small' ? '660px' : '900px'};
    min-height: 360px;
    padding: 20px;

    border-radius: 20px;
    background-color: #fff;

    overflow-y: auto;

    @media (max-width: 979px) {
        width: 580px;
    }

    @media (max-width: 599px) {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 15px;
    }
`;

export const CloseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;

    border-radius: 50%;
    box-shadow: 0px 0px 12px rgba(6, 5, 50, 0.12);
    background-color: #fff;

    width: 48px;
    height: 48px;

    @media (max-width: 599px) {
        right: 15px;
        top: 15px;
    }
`;

import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;

    @media (max-width: 959px) {
        padding: 25px 0;
    }
`;

export const Title = styled.div`
    font-size: 36px;
    margin-bottom: 30px;

    @media (max-width: 959px) {
        font-size: 24px;
    }
`;

export const Item = styled.div`
    display: flex;
    width: 625px;
    margin-bottom: 30px;

    @media (max-width: 959px) {
        width: 100%;
    }
`;

export const ItemNumberBlock = styled.div`
    margin-right: 30px;
`;

export const ItemNumber = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border: 2px solid #ff6900;
    border-radius: 50%;
    font-size: 24px;
    color: #ff6900;
`;

export const ItemTextBlock = styled.div``;

export const ItemTextTitle = styled.div`
    font-size: 24px;
    margin-bottom: 10px;

    @media (max-width: 959px) {
        font-size: 18px;
    }
`;

export const ItemTextDescription = styled.div`
    font-size: 14px;
`;
import styled from 'styled-components';

export const Button = styled.button`
    display: flex;
    position: fixed;
    right: 20px;
    bottom: 70px;
    background-color: #FFF0E5;
    outline: none;
    border: none;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 12px rgba(6, 5, 50, 0.12);

    @media (max-width: 599px) {
        bottom: 120px;
        height: 56px;
        width: 56px;
    }
`;

export const Badge = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ff6900;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;
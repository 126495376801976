import styled from 'styled-components';

export const Component = styled.div`
    background-color: #000;
    padding: 100px 0 60px;
    color: #fff;

    @media (max-width: 1299px) {
        padding: 60px 0;
    }

    @media (max-width: 979px) {
        padding: 30px 0;
    }

    @media (max-width: 599px) {
        padding: 20px 0;
    }
`;

export const Block = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 979px) {
        flex-wrap: wrap;
    }
`;

export const DesignBlock = styled.div`
    margin-top: 30px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;

    &.small-screen {
        display: none;
    }

    @media (max-width: 979px) {
        width: 100%;
        margin-bottom: 25px;

        &.big-screen {
            display: none;
        }

        &.small-screen {
            display: flex;
        }
    }
`;

export const ColumnTitle = styled.div`
    font-size: 18px;
    color: #4d4d4d;
    margin-bottom: 15px;
`;

export const ColumnItem = styled.div`
    font-size: 18px;
    margin-bottom: 15px;
`;

export const LinkPhone = styled.a`
    color: #fff;
    text-decoration: none;
`;

export const Link = styled.a`
    color: #fff;
`;
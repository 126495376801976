import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 320px;
    padding: 15px 30px;

    @media (max-width: 599px) {
        height: 100%;
        align-items: center;
        justify-content: center;

        padding: 0;
    }
`;

export const Title = styled.div`
    font-size: 36px;
    font-weight: bold;

    @media (max-width: 599px) {
        font-size: 24px;
        text-align: center;
        margin-bottom: 50px;
    }
`;

export const PhoneNumberBlock = styled.div`
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    padding-top: 50px;

    @media (max-width: 599px) {
        flex-grow: unset;
        flex-direction: column;
        width: 320px;
    }
`;

export const PhoneNumberLabel = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: 20px;
    font-size: 18px;
    height: 56px;

    @media (max-width: 599px) {
        height: auto;
        margin-right: 0;
        margin-bottom: 15px;
    }
`;

export const PhoneContainer = styled.div`
    flex-grow: 1;
    width: 310px;

    @media (max-width: 599px) {
        width: 100%;
    }
`;

export const PhoneBlock = styled.div`
    display: flex;
    border: 2px solid ${props => props.error ? '#ff0000' : '#FF6900'};
    border-radius: 10px;
    padding: 15px;

    @media (max-width: 599px) {
        width: 100%;
    }
`;

export const PhoneCode = styled.div`
    font-size: 18px;
    margin-right: 15px;
`;

export const PhoneInput = styled.input`
    font-family: Stapel, sans-serif;
    background-color: transparent;
    border: none;
    color: #FF6900;
    font-size: 18px;

    &:focus {
        outline: none;
    }
`;

export const PhoneError = styled.div`
    font-size: 14px;
    color: #ff0000;
    margin-top: 3px;
`;

export const ConfirmBlock = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
    padding: 15px;
    font-size: 14px;
    border-radius: 10px;
    background-color: #FF6900;
    color: #fff;

    @media (max-width: 599px) {
        margin-top: 20px;
    }
`;

export const LoadingBlock = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .9);

    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
`;
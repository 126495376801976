import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        pizzas: [],
        drinks: [],
    },
    reducers: {
        setPizzas: (state, action) => {
            state.pizzas = action.payload;
        },
        setDrinks: (state, action) => {
            state.drinks = action.payload;
        },
    }
});

export const { setIsAuth, setUserInfo, logout } = appSlice.actions;

export default appSlice.reducer;

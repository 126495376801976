import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import SnackbarProvider from 'react-simple-snackbar';

import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './index.css';

import store from './store';
import App from './App';

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <SnackbarProvider>
                <App />
            </SnackbarProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
);

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoSmallIcon } from '../../../../assets/icons/logo-small.svg';

const Block = styled.div`
    margin-bottom: 25px;

    &.no-margin {
        margin-bottom: 0;
    }
`;

const LogoBlock = ({ noMargin }) => {
    return (
        <Block className={noMargin && 'no-margin'}>
            <Link to="/">
                <LogoSmallIcon />        
            </Link>
        </Block>
    )
};

export default LogoBlock;

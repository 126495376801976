import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setItems, setIsLoading } from '../../../../store/pizzaSlice';
import { getProducts } from '../../../../api'
import { pizzaCategoryId } from '../../../../configs';
import { mediaUrl } from '../../../../configs';

import { Container } from '../../../../components/ui';
import {
    Block,
    Products,
    Title,
    LoadingBlock,
} from './components';
import ProductComponent from '../../../../components/product';
import Loading from '../../../../components/dotsLoading';

import ProductModal from '../../../../components/modals/productModal';

const PizzasComponent = ({ pizzasRef }) => {
    const dispatch = useDispatch();
    const { items, isLoading } = useSelector(state => state.pizza);

    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    useEffect(() => {
        getProductsFromServer();
    }, []);

    const getProductsFromServer = async () => {
        const result = await getProducts(pizzaCategoryId);        
        dispatch(setItems(result));
        dispatch(setIsLoading(false));
    };

    const render = useMemo(() => {
        return items.map((item, index) => {
            return (
                <ProductComponent 
                    key={`pizza-${index}`}
                    image={`${mediaUrl}${item.products.products[0].product_pic}`}
                    title={item.name}
                    description={item.products.products[0].product_about}
                    price={`от ${item.products.prod_min_price} tjs`}
                    buttonText="Выбрать"
                    onSelect={() => setSelectedItemIndex(index)}
                />
            )
        })
    }, [items]);

    return (
        <Container ref={pizzasRef}>
            <Block>
                <Title>
                    Пицца
                </Title>

                <Products>
                    {isLoading && (
                        <LoadingBlock>
                            <Loading />
                        </LoadingBlock>
                    )}
                    
                    {render}
                </Products>

                {selectedItemIndex !== null && (
                    <ProductModal 
                        product={items[selectedItemIndex]}
                        onClose={() => setSelectedItemIndex(null)}
                    />
                )}
            </Block>
        </Container>
    )
};

export default PizzasComponent;

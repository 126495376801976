import React from 'react';

import { 
    Component, 
    Block, 
    DesignBlock,
    Column,
    Link,
} from './components';
import { Container } from '../ui';

import SocialsBlock from './parts/socials';
import LogoBlock from './parts/logo';
import MenuBlock from './parts/menu';
import ContactsBlock from './parts/contacts';
import FaqBlock from './parts/faq';

const Footer = ({
    pizzasRef,
    drinksRef,
    howToOrderRef,
    whyUsRef,
}) => {
    return (
        <Component>
            <Container>
                <Block>
                    <Column className="big-screen">
                        <LogoBlock />
                        <SocialsBlock />
                    </Column>

                    <Column className="small-screen">
                        <LogoBlock 
                            noMargin
                           
                        />
                    </Column>

                    <Column>
                        <MenuBlock 
                            pizzasRef={pizzasRef}
                            drinksRef={drinksRef}
                        />
                    </Column>

                    <Column>
                        <ContactsBlock />
                    </Column>

                    <Column>
                        <FaqBlock 
                            howToOrderRef={howToOrderRef}
                            whyUsRef={whyUsRef}
                        />
                    </Column>

                    <Column className="small-screen">
                        <SocialsBlock />
                    </Column>
                </Block>

                <DesignBlock>
                    <div style={{ flexGrow: 1, textAlign: 'right' }}>
                        Дизайнено в <Link target="_blank" href="https://www.instagram.com/yusufjon95/">Ахмедова</Link>
                    </div>
                </DesignBlock>
            </Container>
        </Component>
    )
};

export default Footer;

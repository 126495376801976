import React from 'react';
import moment from 'moment';

import { ReactComponent as InstagramIcon } from '../../../../assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../../../assets/icons/facebook.svg';
import { ReactComponent as TelegramIcon } from '../../../../assets/icons/telegram.svg';
import { ReactComponent as YoutubeIcon } from '../../../../assets/icons/youtube.svg';

import {
    Block,
    LinksBlock,
    Link,
    Copyright,
} from './components';

const SocialsBlock = () => {
    return (
        <Block>
            <LinksBlock>
                <Link href="https://www.instagram.com/sorpizza.bokhtar/">
                    <InstagramIcon />
                </Link>

                {/* <Link href="#">
                    <FacebookIcon />
                </Link> */}

            </LinksBlock>
            
            <Copyright>
                <span>SorPizza,</span> &copy; {moment().format('YYYY')}
            </Copyright>
        </Block>
    )
};

export default SocialsBlock;

import React, { useCallback } from 'react';

import {
    Component,
    Header,
    HeaderLogo,
    HeaderCloseButton,
    Body,
    List,
    Item,
    Contacts,
    ContactRow,
    ContactIconBlock,
    ContactInfoBlock,
    ContactInfoTitle,
    ContactInfoDescription,
} from './components';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as LogoSmallIcon } from '../../assets/icons/logo-small.svg';
import { ReactComponent as MobileIcon } from '../../assets/icons/mobile.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';

import { callCenterPhone, location } from '../../configs';

const MobileMenuComponent = ({ 
    isActive, 
    close,
    howToOrderRef,
    whyUsRef,
}) => {

    const scrollToPosition = useCallback((top) => {
        close();

        window.scrollTo({
            top,
            behavior: 'smooth',
        });
    }, []);

    if (!isActive) return null;

    return (
        <Component isActive={isActive}>
            <Header>
                <HeaderLogo>
                    <LogoSmallIcon />
                </HeaderLogo>

                <HeaderCloseButton onClick={() => close()}>
                    <CloseIcon />
                </HeaderCloseButton>
            </Header>

            <Body>
                <List>
                    <Item onClick={() => scrollToPosition(howToOrderRef.current.offsetTop - 56)}>Как заказать?</Item>
                    <Item onClick={() => scrollToPosition(whyUsRef.current.offsetTop - 56)}>Почему мы?</Item>
                </List>
            </Body>

            <Contacts>
                <ContactRow>
                    <ContactIconBlock>
                        <MobileIcon />
                    </ContactIconBlock>

                    <ContactInfoBlock>
                        <ContactInfoTitle href={`tel:${callCenterPhone}`}>
                            {callCenterPhone}
                        </ContactInfoTitle>

                        <ContactInfoDescription>
                            Доставка от 10 сомони
                        </ContactInfoDescription>
                    </ContactInfoBlock>
                </ContactRow>

                <ContactRow>
                    <ContactIconBlock>
                        <LocationIcon />
                    </ContactIconBlock>
                    
                    <ContactInfoBlock>
                        <ContactInfoTitle>
                            {location}
                        </ContactInfoTitle>
                    </ContactInfoBlock>
                </ContactRow>
            </Contacts>
        </Component>
    )
};

export default MobileMenuComponent;

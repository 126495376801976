import React from 'react';

import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/youtube.svg';

import ModalComponent from '../../modal';
import {
    Block,
    Text,
    LinksBlock,
    Link,
} from './components';

const WaitAndSubscribeModal = ({ onClose }) => {
    return (
        <ModalComponent size="small" onClose={onClose}>
            <Block>
                <ClockIcon />

                <Text>Наш оператор перезвонит Вам в течении <nobr>3 минут.</nobr></Text>
                <Text>Пока Вы можете подписаться на нас в соц. сетях.</Text>

                <LinksBlock>
                    <Link href="https://www.instagram.com/sorpizza.tj/?utm_medium=copy_link">
                        <InstagramIcon />
                    </Link>
                    {/* 
                    <Link href="#">
                        <FacebookIcon />
                    </Link>
                     */}
                </LinksBlock>
            </Block>
        </ModalComponent>
    )
};

export default WaitAndSubscribeModal;

import styled from 'styled-components';

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 30px;

    height: 100%;
`;

export const Text = styled.div`
    margin-top: 30px;
    text-align: center;
    color: #000;

    @media (max-width: 599px) {
        margin-top: 50px;
    }
`;

export const LinksBlock = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
`;

export const Link = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #FFF0E5;

    color: #FF6900;

    &:nth-last-of-type(1) {
        margin-right: 0;
    }
`;
import { createSlice } from '@reduxjs/toolkit';

export const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        items: [],
        mobileItems: [],
        isLoading: true,
    },
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload;
        },
        setMobileItems: (state, action) => {
            state.mobileItems = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
});

export const { setItems, setMobileItems, setIsLoading } = bannerSlice.actions;

export default bannerSlice.reducer;

import React from 'react';

import {
    Block,
    ImageBlock,
    Image,
    InfoBlock,
    Title,
    Description,
    PriceAndAdd,
    Price,
    Add,
    AddWithPrice,
} from './components';

const ProductComponent = ({
    image,
    title,
    description,
    price,
    buttonText,
    onSelect
}) => {

    return (
        <Block onClick={() => onSelect()}>
            <ImageBlock>
                <Image src={image} alt="product-image" />
            </ImageBlock>

            <InfoBlock>
                <Title>{title}</Title>

                <Description>{description}</Description>

                <PriceAndAdd>
                    <Price>{price}</Price>

                    <Add>
                        {buttonText}
                    </Add>

                    <AddWithPrice>
                        {price}
                    </AddWithPrice>
                </PriceAndAdd>
            </InfoBlock>
        </Block>
    )
};

export default ProductComponent;

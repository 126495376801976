import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";

import { Button, Badge } from './components';

import { ReactComponent as Basket } from '../../assets/icons/basket.svg';

const CartButton = () => {
    const history = useHistory();
    const { items } = useSelector(state => state.cart);

    const handleButtonClick = () => {
        history.push('/cart');
    }

    return (
        <Button onClick={() => handleButtonClick()}>
            <Basket style={{ display: 'block' }} />

            {items.length > 0 && (
                <Badge>{items.length}</Badge>
            )}
        </Button>
    )
};

export default CartButton;

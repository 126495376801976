import React from 'react';

import { Button } from './components';

import { ReactComponent as PhoneGreen } from '../../assets/icons/phoneGreen.svg';
import { callCenterPhone } from '../../configs';

const CallButton = () => {
    return (
        <Button href={`tel:${callCenterPhone}`}>
            <PhoneGreen style={{ display: 'block' }} />
        </Button>
    )
};

export default CallButton;

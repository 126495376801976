import React from 'react';
import { useDispatch } from 'react-redux';

import { mediaUrl } from '../../../../configs';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { plusCount, minusCount, removeItem } from '../../../../store/cartSlice';

import {
    Desktop,
    Mobile,
    Top,
    Bottom,
    Image,
    NameAndDescription,
    Name,
    Description,
    Counts,
    CountButton,
    Price,
    Remove,
} from './components';

import { ReactComponent as RemoveIcon } from '../../../../assets/icons/remove.svg';

const ItemComponent = ({ product, index }) => {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();

    let p_url = product.product.product_pic;
    if(!p_url.startsWith('http')) {
        p_url = `${mediaUrl}${product.product.product_pic}`;
    }

    if (width > 979) {
        return (
            <Desktop>
                <Image src={p_url} alt="product-image" />
                {/* <Image src={`${mediaUrl}${product.product.product_pic}`} alt="product-image" /> */}

                <NameAndDescription>
                    <Name>{product.name}</Name>
                    
                    <Description>{product.type === 'pizza' ? product.product.product_name : ''} {product.product.product_short}</Description>
                </NameAndDescription>

                <Counts>
                    <CountButton 
                        disabled={product.count === 1}
                        onClick={() => dispatch(minusCount(index))}
                    >
                        -
                    </CountButton>
                    
                    <span>{product.count}</span>
                    
                    <CountButton 
                        disabled={product.count === parseInt(product.product.total_count_in_store)}
                        onClick={() => dispatch(plusCount(index))}
                    >
                        +
                    </CountButton>
                </Counts>

                <Price>{product.product.product_price} tjs / 1 шт.</Price>

                <Remove onClick={() => dispatch(removeItem(index))}>
                    <RemoveIcon />
                </Remove>
            </Desktop>
        )
    } else {
        return (
            <Mobile>
                <Top>
                    <Image src={p_url} alt="product-image" />

                    <NameAndDescription>
                        <Name>{product.name}</Name>
                        
                        <Description>{product.product.product_name}</Description>
                    </NameAndDescription>

                    <Remove onClick={() => dispatch(removeItem(index))}>
                        <RemoveIcon />
                    </Remove>
                </Top>

                <Bottom>
                    <Price>{product.product.product_price} tjs / 1 шт.</Price>

                    <Counts>
                        <CountButton 
                            disabled={product.count === 1}
                            onClick={() => dispatch(minusCount(index))}
                        >
                            -
                        </CountButton>
                        
                        <span>{product.count}</span>
                        
                        <CountButton 
                            disabled={product.count === parseInt(product.product.total_count_in_store)}
                            onClick={() => dispatch(plusCount(index))}
                        >
                            +
                        </CountButton>
                    </Counts>
                </Bottom>
            </Mobile>
        )
    }
};

export default ItemComponent;

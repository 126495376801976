import React, { useRef } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import './App.css';

import Header from './components/header';
import Footer from './components/footer';

import CartPage from './pages/cart';
import HomePage from './pages/home';
import ScrollToTop from './components/scrollToTop';
import CallButton from './components/callButton';

function App() {
    const pizzasRef = useRef();
    const drinksRef = useRef();
    const howToOrderRef = useRef();
    const whyUsRef = useRef();

    return (
        <>
            <ScrollToTop />
            <div className="content">
                <Header 
                    pizzasRef={pizzasRef}
                    drinksRef={drinksRef}
                    howToOrderRef={howToOrderRef}
                    whyUsRef={whyUsRef}
                />
                
                <Switch>
                    <Route path="/" exact>
                        <HomePage 
                            pizzasRef={pizzasRef}
                            drinksRef={drinksRef}
                            howToOrderRef={howToOrderRef}
                            whyUsRef={whyUsRef}
                        />
                    </Route>

                    <Route path="/cart" exact>
                        <CartPage />
                    </Route>
                    
                    <Redirect to="/" />
                </Switch>
            </div>

            <div className="footer">
                <Footer 
                    pizzasRef={pizzasRef}
                    drinksRef={drinksRef}
                    howToOrderRef={howToOrderRef}
                    whyUsRef={whyUsRef}
                />
            </div>

            <CallButton />
        </>
    );
}

export default App;
